import { receiverApi } from "api/receiver.api";
import { useState } from "react";
import { Receiver, ReceiverQueryParam } from "types/receiver";

export const useReceiver = () => {
  const [loading, setLoading] = useState(false);
  const [receivers, setReceivers] = useState<Receiver[]>([]);

  const fetchReceiver = async (receiverQueryParam?: ReceiverQueryParam) => {
    setLoading(true);
    const res = await receiverApi.findAll(receiverQueryParam);
    setLoading(false);
    setReceivers(res.data.receivers);
  };

  const clearReceiver = () => {
    setReceivers([]);
  };

  const updateReceivers = (data: Receiver[]) => {
    setReceivers([...data]);
  };

  return { receivers, loading, fetchReceiver, clearReceiver, updateReceivers };
};
