export enum Gender {
  Male = "MALE",
  FeMale = "FE_MALE",
}

export const GenderTrans = {
  [Gender.Male]: {
    value: [Gender.Male],
    label: "Nam",
  },
  [Gender.FeMale]: {
    value: [Gender.FeMale],
    label: "Nữ",
  },
};

export const $isDev = process.env.REACT_APP_IS_DEV == "true";
export const $googleApiKey = process.env.REACT_APP_GOOLE_API_KEY;
